import * as React from 'react';
import { Admin, Resource } from 'react-admin';

import { dataProvider } from './dataProvider';
import { authProvider } from './authProvider';
import { i18nProvider } from './i18nProvider';

import { Layout } from './components/Layout';

import Accounts from './resources/accounts';
import Users from './resources/users';

class App extends React.Component {
  render() {
    return (
      <Admin
        layout={Layout}
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        disableTelemetry
      >
        <Resource {...Accounts} />
        <Resource {...Users} />
      </Admin>
    );
  }
}

export default App;
