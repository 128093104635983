import * as React from 'react';
import {
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
} from 'react-admin';
import { FormRow } from 'components/FormLayout';

export const BrandAccessSection = (props: any) => {
  const [initialValue] = React.useState(props.record?.accessibleBrands || []);
  return <></>;
};
