import * as React from 'react';
import {
  List,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  DateInput,
  NumberInput,
  ReferenceField,
  ReferenceManyField,
  ArrayField,
  Filter,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  SelectInput,
  FormDataConsumer,
  SelectArrayInput,
  useNotify,
  Button,
  useDataProvider,
  useRefresh,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import BookIcon from '@material-ui/icons/Book';
import { DateField } from 'components/DateField';
import { Datagrid } from 'components/Datagrid';
import { Account } from 'resources/types';
import { FormRow } from 'components/FormLayout';
import { RoleField } from 'components/RoleField';
import { StripePriceField } from 'components/StripePriceField';
import { createCsvExporter, exportDate } from 'helpers/exporter';
import { BrandAccessSection } from 'components/BrandAccessSection';
import { storage_key } from 'authProvider';
import { storage } from 'react-admin-loopback';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import AddIcon from '@material-ui/icons/Add';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';

export const AccountIcon = BookIcon;

const rowToExport = (account: Account) => ({
  id: account.id,
  organizationName: account.organizationName,
  companyType: account.companyType,
  customerType: account.customerType,
  ownerEmail: account.owner?.email,
  status: account.status,
  trialEnd: exportDate(account.trialEnd),
  licenseEndDate: exportDate(account.licenseEndDate),
  createdAt: exportDate(account.createdAt),
  ownerReferrer: account.owner?.referrer,
  jobRole: account.jobRole,
  chain: account.chain,
  featureAccess: account.featureAccess,
});

const choices = [
  { id: 'Team', name: 'Team' },
  { id: 'Partner', name: 'Partner' },
  { id: 'Customer', name: 'Customer' },
  { id: 'Prospect', name: 'Prospect' },
];

const featureChoices = [
  { id: 'SiteMatchReport', name: 'Site Match Report' },
  { id: 'Dashboard', name: 'Dashboard' },
];

const accountExporter = createCsvExporter('accounts', rowToExport);

const validateAccountCreation = async (values: Account) => {
  const errors: any = {};
  const token = storage.load(storage_key);

  if (!values?.trialEnd && !values?.licenseEndDate) {
    errors.trialEnd =
      'You must enter either a trial end date, License end date or Stripe ID';
    errors.licenseEndDate =
      'You must enter either a trial end date, License end date or Stripe ID';
  }

  if (values.featureAccess?.includes('Dashboard') && !values.chain) {
    errors.chain = 'Chain ID is required when Dashboard feature is enabled';
  }

  if (!!values.chain) {
    const chainResponse = await fetch(`/chain/${values.chain}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token.id}`,
      },
    });

    if (!chainResponse.ok) {
      errors.chain = 'Invalid Chain ID';
    }
  }

  return errors;
};

const AccountFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <NumberInput source="id" label="Account ID" />
    <TextInput source="organizationName" label="Organization" />
    <DateInput source="trialEnd_lte" label="Trial End before" />
    <DateInput source="trialEnd_gte" label="Trial End after" />
    <DateInput source="createdAt_lte" label="Created before" />
    <DateInput source="createdAt_gte" label="Created after" />
    <TextInput source="user.referrer" label="Referrer" />
  </Filter>
);

export const AccountList = (props: any) => (
  <List
    filters={<AccountFilter />}
    bulkActionButtons={false}
    {...props}
    exporter={accountExporter}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="organizationName" label="Organization" />
      <TextField source="companyType" label={'Company Type'} />
      <TextField source="customerType" label={'Customer Type'} />
      <ReferenceField source="ownerId" reference="users" sortable={false}>
        <TextField source="email" />
      </ReferenceField>
      <TextField source="status" sortable={false} />
      <DateField source="trialEnd" />
      <DateField source="licenseEndDate" />
    </Datagrid>
  </List>
);

const AccountTitle = ({ record }: any) => {
  return <span>Account {record ? `"${record.organizationName}"` : ''}</span>;
};

const AccountEditToolbar = (props: any) => {
  return (
    <Toolbar {...props}>
      <Box display="flex" justifyContent="space-between" width="100%">
        <DeleteButton {...props} undoable={false} />

        <FormDataConsumer>
          {({ formData }: any) => (
            <SaveButton
              {...props}
              handleSubmitWithRedirect={() => {
                const isChainSame = formData.chain === props.record.chain;
                if (
                  !isChainSame &&
                  !window.confirm(
                    'Changing the chain linked to this account will erase all dashboards. Do you want to continue?'
                  )
                ) {
                  return false;
                }
                if (!window.confirm('Really update account?')) {
                  return false;
                }
                return props.handleSubmitWithRedirect();
              }}
            />
          )}
        </FormDataConsumer>
      </Box>
    </Toolbar>
  );
};

const AddUserButton = ({ record }: { record: any }) => {
  const [open, setOpen] = React.useState(false);
  const [emails, setEmails] = React.useState('');
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleSubmit = async () => {
    try {
      const emailList = emails
        .split(',')
        .map((email) => email.trim())
        .filter(Boolean);

      const token = storage.load(storage_key);
      const response = await fetch('/admin/invite-users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token.id}`,
        },
        body: JSON.stringify({
          accountId: record.id,
          emails: emailList,
        }),
      });

      if (!response.ok) {
        const errorData = await response.text();
        console.log('errorData: ', errorData);
        throw new Error(errorData ?? 'Unknown error');
      }

      notify('Users invited successfully');
      setOpen(false);
      setEmails('');
      refresh();
    } catch (error: any) {
      console.log('error: ', error);
      notify(error?.message || 'Error inviting users', { type: 'error' });
    }
  };

  return (
    <>
      <Button
        label="Add New User"
        onClick={() => setOpen(true)}
        style={{ marginLeft: '10px' }}
        variant="contained"
        color="primary"
      >
        <AddIcon />
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Add new user(s)</DialogTitle>
        <DialogContent>
          <TextareaAutosize
            style={{
              width: '100%',
              maxWidth: '100%',
              minHeight: '200px',
              padding: '12px',
              fontSize: '16px',
              marginTop: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              boxSizing: 'border-box',
            }}
            placeholder="Enter email addresses (seperated by comma)"
            value={emails}
            onChange={(e) => setEmails(e.target.value)}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button
            label="Cancel"
            onClick={() => setOpen(false)}
            color="primary"
          />
          <Button
            label="Continue"
            onClick={handleSubmit}
            color="primary"
            variant="contained"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export const AccountEdit = (props: any) => {

  return (
    <Edit title={<AccountTitle />} mutationMode="pessimistic" {...props}>
      <SimpleForm
        toolbar={<AccountEditToolbar />}
        submitOnEnter={false}

        validate={validateAccountCreation}
      >
        <h2>Account profile</h2>
        <FormRow>
          <TextInput source="id" fullWidth disabled />
          <TextInput source="organizationName" label="Organization" fullWidth />
          <SelectInput
            source="companyType"
            label={'Company Type'}
            fullWidth
            choices={choices}
            validate={[required()]}
          />
          <TextInput source="customerType" label={'Customer Type'} fullWidth />
        </FormRow>
        <FormRow>
          <TextInput
            source="owner.email"
            label="Admin email"
            fullWidth
            disabled
          />
        </FormRow>

        <h2>Access details</h2>
        <FormRow>
          <FormDataConsumer>
            {({ formData }: any) => (
              <Box
                display="flex"
                flexDirection="row"
                flexGrow={1}
                flex={'12px'}
                gridGap={12}
              >
                <DateInput
                  source="trialEnd"
                  fullWidth
                  disabled={!!formData.licenseEndDate}
                />
                <DateInput
                  source="licenseEndDate"
                  fullWidth
                  disabled={!!formData.trialEnd}
                />
              </Box>
            )}
          </FormDataConsumer>
          <TextInput source="status" fullWidth disabled />
        </FormRow>
        <FormRow>
          <TextInput source="chain" label="Chain ID" fullWidth />
          <SelectArrayInput
            source="featureAccess"
            choices={featureChoices}
            fullWidth
          />
        </FormRow>

        <Box
          display="flex"
          alignItems="center"
          width="100% !important"
          justifyContent="space-between"
        >
          <h2 style={{ marginBottom: 0 }}>All users in account</h2>
          <FormDataConsumer>
            {({ formData }: any) => <AddUserButton record={formData} />}
          </FormDataConsumer>
        </Box>

        <ReferenceManyField
          addLabel={false}
          source="id"
          reference="users"
          target="accountId"
          emptyText="No users found."
          fullWidth
          {...props}
        >
          <Datagrid rowClick="edit">
            <TextField source="email" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <RoleField source="role" />
          </Datagrid>
        </ReferenceManyField>

        <BrandAccessSection />
      </SimpleForm>
    </Edit>
  );
};

export const AccountCreate = (props: any) => {
  return (
    <Create title={<AccountTitle />} {...props}>
      <SimpleForm
        submitOnEnter={false}
        warnWhenUnsavedChanges
        validate={validateAccountCreation}
      >
        <h2>Account details</h2>
        <FormRow>
          <TextInput source="organizationName" fullWidth />
          <TextInput source="hubspotId" fullWidth />
        </FormRow>
        <FormRow>
          <SelectInput
            source="companyType"
            label={'Company Type'}
            fullWidth
            choices={choices}
            validate={[required()]}
          />
          <TextInput source="customerType" fullWidth />
        </FormRow>
        <FormRow>
          <FormDataConsumer>
            {({ formData }: any) => {
              return (
                <Box
                  display="flex"
                  flexDirection="row"
                  flexGrow={1}
                  flex={'12px'}
                  gridGap={12}
                >
                  <DateInput
                    source="trialEnd"
                    fullWidth
                    disabled={!!formData.licenseEndDate}
                  />
                  <DateInput
                    source="licenseEndDate"
                    fullWidth
                    disabled={!!formData.trialEnd}
                  />
                </Box>
              );
            }}
          </FormDataConsumer>
        </FormRow>

        <FormRow>
          <TextInput source="chain" label="Chain ID" fullWidth />
          <SelectArrayInput
            source="featureAccess"
            choices={featureChoices}
            fullWidth
          />
        </FormRow>

        <h2>User admin details</h2>
        <FormRow>
          <TextInput source="firstName" fullWidth validate={[required()]} />
          <TextInput source="lastName" fullWidth validate={[required()]} />
          <TextInput source="email" fullWidth validate={[required()]} />
        </FormRow>

        <BrandAccessSection />
      </SimpleForm>
    </Create>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: 'accounts',
  list: AccountList,
  edit: AccountEdit,
  create: AccountCreate,
  icon: AccountIcon,
};
